import { useState, useEffect } from 'react';
import axiosInstance from 'src/utils/axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import { countries, emaildomains } from 'src/assets/data';
import {
    Checkbox,
    Link,
    Stack,
    IconButton,
    Typography,
    Snackbar,
    SnackbarContent,
    TextField,
    InputAdornment,
    Button,
    Dialog,
    DialogContent,
    Alert,
} from '@mui/material';
import { useSnackbar } from 'src/components/snackbar';
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import {
    CheckCircleOutline as CheckCircleOutlineIcon,
    ErrorOutline as ErrorOutlineIcon,
} from '@mui/icons-material';
import SmsIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { CloseIcon } from 'yet-another-react-lightbox';
import FormProvider, { RHFAutocomplete } from 'src/components/hook-form';
// ----------------------------------------------------------------------

export default function ModernRegisterView() {
    const [showverifyDialog, setShowverifyDialog] = useState(false);
    const [showverifyemailDialog, setShowverifyEmailDialog] = useState(false);
    const [isphonenumberverified, setIsPhoneNumberVerified] = useState(false);
    // const [isemailverified, setIsEmailVerified] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('+91');
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [loading, setloading] = useState(false);
    // const [loading1, setloading1] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        organisation: '',
        phonenumber: '',
        email: '',
        isChecked: '',
        is_phone_number_verified: isphonenumberverified,
        isEmailVerified: true,
        country: 'India',
    });
    console.log('emaildomains', emaildomains);
    const [errorMessage, setErrorMessage] = useState('');
    console.log('formdata', formData);
    console.log('isphonenumberverified', isphonenumberverified);

    const { enqueueSnackbar } = useSnackbar();
    const [selectedCountry, setSelectedCountry] = useState('India');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const navigate = useNavigate();
    const [Otp, setOtp] = useState('');
    const [Otp1, setOtp1] = useState('');
    const initialTime = 1 * 60;
    const [seconds, setSeconds] = useState(initialTime);
    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const remainingSeconds = timeInSeconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const handleChange = (newValue) => {
        setOtp(newValue);
    };
    const handleChange1 = (newValue) => {
        setOtp1(newValue);
    };
    console.log('code', Otp);
    console.log('code1', Otp1);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const phoneNumberValid = phoneNumber.length === 13;

    const isApplyButtonEnabled =
        Object.values(formData).every((value) => value !== '') &&
        // isphonenumberverified === true &&
        // phoneNumberValid &&
        errorMessage === '';

    const handlePhoneNumberChange = (event) => {
        
        setPhoneNumber(event.target.value);
        setIsPhoneNumberVerified(false);
        setFormData({ ...formData, phonenumber: event.target.value });
    };

    const handleCountryChange = (event, newValue) => {
        setSelectedCountry(newValue);
        setFormData({ ...formData, country: newValue });
        const selectedCountryData = countries.find((country) => country.label === newValue);

        if (selectedCountryData) {
            const { phone } = selectedCountryData;
            const formattedPhoneNumber = `+${phone}`;
            setPhoneNumber(formattedPhoneNumber);
        }
    };

    const defaultValues = {
        fullName: '',
        organisation: '',
        phonenumber: '',
        email: '',
        isChecked: '',
        country: '',
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const onSubmit = async () => {
        try {
            const orgdata = {
                organisation: formData.organisation,
                fullName: formData.fullName,
                email: formData.email,
                admin_email: formData.email,
                superadmin_email: formData.email,
                security_alert_emails: JSON.stringify([formData.email]),
            };
            console.log('apidata', orgdata);
            const response = await axiosInstance.post('/org/create_org', orgdata);
            console.log('API response:', response.data);
            console.log('API response Messsage:', response.data?.Message);
            if (response.data?.Status === true) {
                setTimeout(() => {
                    userregister();
                    setSnackbarSeverity('success');
                    setSnackbarMessage(response.data?.Message);
                    setSnackbarOpen(true);
                }, 500);
            } else {
                const orgError = response.data?.Message || response.data?.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(orgError);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.Message);
                setSnackbarOpen(true);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('Something Went Wrong, Please try again later!');
                setSnackbarOpen(true);
            }
        }
    };
    const userregister = async () => {
        try {
            const apiData = {
                fullName: formData.fullName,
                organisation: formData.organisation,
                email: formData.email,
                username: `@${formData.fullName.toLowerCase().replace(/\s/g, '_')}`,
                phonenumber: formData.phonenumber,
                isEmailVerified: true,
                is_phone_number_verified: isphonenumberverified,
                country: selectedCountry,
            };
            console.log('apidata', apiData);
            localStorage.setItem('userdata', JSON.stringify(apiData));
            const response = await axiosInstance.post('/user/create_user', apiData);
            console.log('API response:', response.data);
            console.log('API response Message:', response.data?.Message);

            if (response.data && response.data?.UserConfirmed === false) {
                navigate('/authentication/auth-verify', { state: { apiData } });
                setTimeout(() => {
                    enqueueSnackbar('Confirmation code sent Successfully');
                    setSnackbarSeverity('success');
                    setSnackbarMessage('User Registered Successfully!');
                    setSnackbarOpen(true);
                }, 500);
            } else if (
                response.data &&
                response.data?.User?.UserStatus === 'FORCE_CHANGE_PASSWORD'
            ) {
                const UserStatus = response.data?.User.UserStatus;
                navigate('/authentication/auth-login', { state: { UserStatus, apiData } });
                setTimeout(() => {
                    enqueueSnackbar('Temporary Password sent Successfully');
                    setSnackbarSeverity('success');
                    setSnackbarMessage('User Registered Successfully!');
                    setSnackbarOpen(true);
                }, 500);
            } else {
                const UserError = response.data?.Message || response.data?.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.Message);
                setSnackbarOpen(true);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('Something Went Wrong, Please try again later!');
                setSnackbarOpen(true);
            }
        }
    };

    const smssetup = async () => {
        setloading(true);
        try {
            const smssetupdata = {
                phone_number: formData.phonenumber,
            };
            console.log('smssetupdata', smssetupdata);
            const response = await axiosInstance.post('/sns/send_sms', smssetupdata);
            console.log('API response:', response.data);
            const SMSStatus = response.data?.Status;
            if (SMSStatus === true) {
                setTimeout(() => {
                    setSnackbarSeverity('success');
                    setSnackbarMessage(response.data?.Message);
                    setSnackbarOpen(true);
                    setShowverifyDialog(true);
                    setloading(false);
                }, 500);
            } else {
                const UserError = response.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setloading(false);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                const UserError = error.response.data.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setloading(false);
            } else {
                const UserError = 'Something Went Wrong, Please try again later!';
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setloading(false);
            }
        }
    };

    // const emailsetup = async () => {
    //     setloading1(true);
    //     try {
    //         const emailsetupdata = {
    //             email: formData.email,
    //         };
    //         console.log('emailsetupdata', emailsetupdata);
    //         const response = await axiosInstance.post('/ses/send_temp_email', emailsetupdata);
    //         console.log('API response:', response.data);
    //         const EmailStatus = response.data?.Status;
    //         if (EmailStatus === true) {
    //             setTimeout(() => {
    //                 setSnackbarSeverity('success');
    //                 setSnackbarMessage(response.data?.Message);
    //                 setSnackbarOpen(true);
    //                 setShowverifyEmailDialog(true);
    //                 setloading1(false);
    //             }, 500);
    //         } else {
    //             const UserError = response.data?.Message;
    //             setSnackbarSeverity('error');
    //             setSnackbarMessage(UserError);
    //             setSnackbarOpen(true);
    //             setloading1(false);
    //         }
    //     } catch (error) {
    //         console.error('API error:', error);
    //         if (error.response && error.response.data && error.response.data.data.Message) {
    //             const UserError = error.response.data.Message;
    //             setSnackbarSeverity('error');
    //             setSnackbarMessage(UserError);
    //             setSnackbarOpen(true);
    //             setloading1(false);
    //         } else {
    //             const UserError = 'Something Went Wrong, Please try again later!';
    //             setSnackbarSeverity('error');
    //             setSnackbarMessage(UserError);
    //             setSnackbarOpen(true);
    //             setloading1(false);
    //         }
    //     }
    // };

    const verifysms = async () => {
        setOpen(true);
        try {
            const smsdata = {
                phone_number: formData.phonenumber,
                otp: Otp,
            };
            console.log('smsdata', smsdata);
            const response = await axiosInstance.post('/sns/verify_sms', smsdata);
            console.log('API response:', response.data);
            const SMSStatus = response.data?.Status;
            if (SMSStatus === true) {
                setTimeout(() => {
                    setIsPhoneNumberVerified(true);
                    setSnackbarSeverity('success');
                    setSnackbarMessage(response.data?.Message);
                    setSnackbarOpen(true);
                    setShowverifyDialog(false);
                    setSeconds(initialTime);
                    setOpen(false);
                }, 500);
            } else {
                const UserError = response.data.data?.Message || response.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setOpen(false);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.Message);
                setSnackbarOpen(true);
                setOpen(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('Something Went Wrong, Please try again later!');
                setSnackbarOpen(true);
                setOpen(false);
            }
        }
    };

    const verifyemail = async () => {
        setOpen1(true);
        setSeconds(initialTime);
        try {
            const emaildata = {
                email: formData.email,
                otp: Otp1,
            };
            console.log('emaildata', emaildata);
            const response = await axiosInstance.post('/ses/verify_email', emaildata);
            console.log('API response:', response.data);
            const EmailStatus = response.data?.Status;
            if (EmailStatus === true) {
                setTimeout(() => {
                    setSnackbarSeverity('success');
                    setSnackbarMessage(response.data?.Message);
                    setSnackbarOpen(true);
                    // setIsEmailVerified(true);
                    setShowverifyEmailDialog(false);
                    setOpen1(false);
                }, 500);
            } else {
                const UserError = response.data.data?.Message || response.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setOpen1(false);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.Message);
                setSnackbarOpen(true);
                setOpen1(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('Something Went Wrong, Please try again later!');
                setSnackbarOpen(true);
                setOpen1(false);
            }
        }
    };

    const resendemail = async () => {
        setOpen2(true);
        setSeconds(initialTime);
        try {
            const emaildata = {
                email: formData.email,
            };
            console.log('emaildata', emaildata);
            const response = await axiosInstance.post('/ses/resend_temp_email', emaildata);
            console.log('API response:', response.data);
            const EmailStatus = response.data?.Status;
            if (EmailStatus === true) {
                setTimeout(() => {
                    setOtp1('');
                    setSnackbarSeverity('success');
                    setSnackbarMessage(response.data?.Message);
                    setSnackbarOpen(true);
                    setOpen2(false);
                }, 500);
            } else {
                const UserError = response.data.data?.Message || response.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setOpen2(false);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.Message);
                setSnackbarOpen(true);
                setOpen2(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('Something Went Wrong, Please try again later!');
                setSnackbarOpen(true);
                setOpen2(false);
            }
        }
    };
    const resendsms = async () => {
        setOpen3(true);
        setSeconds(initialTime);
        try {
            const smsdata = {
                phone_number: formData.phonenumber,
            };
            console.log('smsdata', smsdata);
            const response = await axiosInstance.post('/sns/resend_sms', smsdata);
            console.log('API response:', response.data);
            const SMSStatus = response.data?.Status;
            if (SMSStatus === true) {
                setTimeout(() => {
                    setOtp('');
                    setSnackbarSeverity('success');
                    setSnackbarMessage(response.data?.Message);
                    setSnackbarOpen(true);
                    setOpen3(false);
                }, 500);
            } else {
                const UserError = response.data.data?.Message || response.data?.Message;
                setSnackbarSeverity('error');
                setSnackbarMessage(UserError);
                setSnackbarOpen(true);
                setOpen3(false);
            }
        } catch (error) {
            console.error('API error:', error);
            if (error.response && error.response.data && error.response.data.data.Message) {
                setSnackbarSeverity('error');
                setSnackbarMessage(error.response.data.Message);
                setSnackbarOpen(true);
                setOpen3(false);
            } else {
                setSnackbarSeverity('error');
                setSnackbarMessage('Something Went Wrong, Please try again later!');
                setSnackbarOpen(true);
                setOpen3(false);
            }
        }
    };

    const handleFieldChange = (fieldName, value) => {
        if (fieldName === 'email') {
            const domain = value.split('@')[1];
            if (!emaildomains.includes(domain)) {
                setErrorMessage('');
            } else {
                setErrorMessage('Please enter a valid business/work email');
            }
        }
        setFormData((prevData) => ({ ...prevData, [fieldName]: value }));
    };

    const resetFormFields = () => {
        setFormData({
            fullName: '',
            organisation: '',
            phonenumber: '',
            country: '',
            email: '',
            isChecked: '',
        });
        setIsPhoneNumberVerified(false);
        setOtp('');
        setOtp1('');
        // setIsEmailVerified(false);
        setSelectedCountry('');
        setPhoneNumber('');
        setErrorMessage('');
    };

    // const isemailValid = formData.email.length !== 0;
    const otpvalid = Otp.length === 6;
    const otpvalid1 = Otp1.length === 6;

    useEffect(() => {
        if (showverifyDialog || showverifyemailDialog) {
            const intervalId = setInterval(() => {
                if (seconds > 0) {
                    setSeconds((prevSeconds) => prevSeconds - 1);
                }
            }, 1000);

            return () => clearInterval(intervalId);
        }
        return undefined;
    }, [showverifyemailDialog, seconds, showverifyDialog]);

    const renderHead = (
        <Stack sx={{ mb: 3, position: 'relative' }}>
            <Typography variant="h4">Welcome to TeamXcel</Typography>

            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={0.5}>
                    <Typography variant="body2"> Already have an account? </Typography>

                    <Link
                        href={paths.authentication.authlogin}
                        component={RouterLink}
                        variant="subtitle2"
                    >
                        Sign in
                    </Link>
                </Stack>
                <Button
                    variant="contained"
                    onClick={resetFormFields}
                    color="error"
                    disabled={
                        !Object.entries(formData).some(
                            ([key, value]) =>
                                key !== 'is_phone_number_verified' &&
                                key !== 'isEmailVerified' &&
                                value !== ''
                        )
                    }
                >
                    Clear
                </Button>
            </Stack>
        </Stack>
    );

    const renderverify = (
        <Dialog open={showverifyDialog} fullWidth maxWidth="xs" backdropclick="ignore">
            <DialogContent sx={{ p: 2 }}>
                <Stack spacing={2} sx={{ my: 1, alignItems: 'center' }}>
                    <SmsIcon fontSize="large" color="primary" />
                    <Typography variant="h4">SMS Verification</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Please enter the 6-digit Verification Code below to verify.
                    </Typography>
                    <MuiOtpInput
                        value={Otp}
                        onChange={handleChange}
                        gap={1.5}
                        length={6}
                        TextFieldsProps={{
                            placeholder: '-',
                            type: 'number',
                        }}
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                        display="flex"
                        justifyContent="space-between"
                    >
                        <LoadingButton
                            variant="contained"
                            color="warning"
                            fullWidth
                            loading={open3}
                            disabled={seconds !== 0}
                            onClick={resendsms}
                        >
                            Resend{' '}
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            fullWidth
                            loading={open}
                            disabled={!otpvalid}
                            onClick={verifysms}
                        >
                            Submit{' '}
                        </LoadingButton>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setShowverifyDialog(false);
                                setOtp('');
                            }}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Stack>
                    <Alert severity="warning">
                        {' '}
                        Verification code will expire in {formatTime(seconds)}
                    </Alert>
                </Stack>
            </DialogContent>
        </Dialog>
    );

    const renderverifyemail = (
        <Dialog open={showverifyemailDialog} fullWidth maxWidth="xs" backdropclick="ignore">
            <DialogContent sx={{ p: 3 }}>
                <Stack spacing={2} sx={{ my: 1, alignItems: 'center' }}>
                    <EmailIcon fontSize="large" color="primary" />
                    <Typography variant="h4">Email Verification</Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', textAlign: 'center' }}
                    >
                        We have emailed a 6-digit confirmation code to {formData.email}, please
                        enter the code in below box to verify your email.
                    </Typography>
                    <MuiOtpInput
                        value={Otp1}
                        onChange={handleChange1}
                        gap={1.5}
                        length={6}
                        TextFieldsProps={{
                            placeholder: '-',
                            type: 'number',
                        }}
                    />
                    <Stack
                        direction="row"
                        spacing={2}
                        display="flex"
                        justifyContent="space-between"
                        mt={3}
                    >
                        <LoadingButton
                            variant="contained"
                            color="warning"
                            fullWidth
                            loading={open2}
                            disabled={seconds !== 0}
                            onClick={resendemail}
                        >
                            Resend{' '}
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            color="success"
                            fullWidth
                            loading={open1}
                            disabled={!otpvalid1}
                            onClick={verifyemail}
                        >
                            Submit{' '}
                        </LoadingButton>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                setShowverifyEmailDialog(false);
                                setOtp1('');
                            }}
                            fullWidth
                        >
                            Cancel
                        </Button>
                    </Stack>
                    <Alert severity="warning">
                        {' '}
                        Verification code will expire in {formatTime(seconds)}
                    </Alert>
                </Stack>
            </DialogContent>
        </Dialog>
    );

    const renderForm = (
        <Stack spacing={2}>
            <TextField
                name="fullName"
                label="Full Name"
                sx={{ width: '100%' }}
                value={formData.fullName}
                onChange={(e) => handleFieldChange('fullName', e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:user-bold-duotone" width={24} />
                        </InputAdornment>
                    ),
                }}
            />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <RHFAutocomplete
                    name="country"
                    label="Country Code"
                    options={countries.map((country) => country.label)}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={formData.country || selectedCountry}
                    onChange={handleCountryChange}
                    renderOption={(props, option) => {
                        const { code, label, phone } = countries.filter(
                            (country) => country.label === option
                        )[0];

                        if (!label) {
                            return null;
                        }

                        return (
                            <li {...props} key={label}>
                                <Iconify
                                    key={label}
                                    icon={`circle-flags:${code.toLowerCase()}`}
                                    width={28}
                                    sx={{ mr: 1 }}
                                />
                                {label} ({code}) +{phone}
                            </li>
                        );
                    }}
                    sx={{ width: '60%' }}
                />
                <TextField
                    name="phonenumber"
                    label="Phone number"
                    value={phoneNumber || formData.phonenumber}
                    onChange={handlePhoneNumberChange}
                    sx={{ width: '100%' }}
                    placeholder="+919999999999"
                    disabled={isphonenumberverified}
                    // helperText="Include Country Code"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Iconify icon="solar:phone-bold-duotone" width={24} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <LoadingButton
                                    loading={loading}
                                    variant="contained"
                                    color={isphonenumberverified ? 'success' : 'warning'}
                                    disabled={isphonenumberverified || !phoneNumberValid}
                                    onClick={smssetup}
                                >
                                    {isphonenumberverified ? 'Verified' : 'Verify'}
                                </LoadingButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            {/* <TextField
                name="email"
                label="Business Email"
                type="email"
                sx={{ width: '100%' }}
                placeholder="example@company.com"
                onChange={(e) => handleFieldChange('email', e.target.value)}
                value={formData.email}
                // disabled={isemailverified}
                // InputProps={{
                //     endAdornment: (
                //         <InputAdornment position="end">
                //             <LoadingButton
                //                 loading={loading1}
                //                 variant="contained"
                //                 color={isemailverified ? 'success' : 'warning'}
                //                 disabled={isemailverified || !isemailValid}
                //                 onClick={emailsetup}
                //             >
                //                 {isemailverified ? 'Verified' : 'Verify'}
                //             </LoadingButton>
                //         </InputAdornment>
                //     ),
                // }}
            /> */}
            <TextField
                name="email"
                label="Business Email"
                type="email"
                sx={{ width: '100%' }}
                placeholder="example@domain"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="ic:twotone-email" width={24} />
                        </InputAdornment>
                    ),
                }}
                onChange={(e) => handleFieldChange('email', e.target.value)}
                value={formData.email}
                error={!!errorMessage}
                helperText={errorMessage}
            />

            <TextField
                name="organisation"
                label="Company Name"
                sx={{ width: '100%' }}
                value={formData.organisation}
                onChange={(e) => handleFieldChange('organisation', e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify icon="solar:buildings-bold-duotone" width={24} />
                        </InputAdornment>
                    ),
                }}
            />

            <Alert severity="info">Please verify your phone number to create an account.</Alert>

            <Stack direction="row" alignItems="center">
                <Checkbox
                    color="primary"
                    checked={formData.isChecked}
                    onChange={(e) => {
                        const checkedValue = e.target.checked ? 'checked' : '';
                        handleFieldChange('isChecked', checkedValue);
                    }}
                    size="10px"
                />
                <Typography
                    component="div"
                    sx={{
                        color: 'text.secondary',
                        typography: 'caption',
                        textAlign: 'center',
                        fontSize: '11.4px',
                    }}
                >
                    {'By signing up, I agree to '}
                    <Link underline="always" color="text.primary">
                        Terms of Service
                    </Link>
                    {' and '}
                    <Link underline="always" color="text.primary">
                        Privacy Policy
                    </Link>
                    .
                </Typography>
            </Stack>
            <LoadingButton
                fullWidth
                color="inherit"
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
                endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
                disabled={!isApplyButtonEnabled}
                sx={{ justifyContent: 'space-between', pl: 2, pr: 1.5 }}
            >
                Create account
            </LoadingButton>
        </Stack>
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {renderHead}
            {renderverify}
            {renderForm}
            {renderverifyemail}

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <SnackbarContent
                    style={{
                        backgroundColor: snackbarSeverity === 'error' ? '#f44336' : '#4caf50',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    message={
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                            {snackbarSeverity === 'error' ? (
                                <ErrorOutlineIcon style={{ marginRight: '8px' }} />
                            ) : (
                                <CheckCircleOutlineIcon style={{ marginRight: '8px' }} />
                            )}
                            {snackbarMessage}
                        </span>
                    }
                    action={
                        <IconButton size="small" color="inherit" onClick={handleCloseSnackbar}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </FormProvider>
    );
}
