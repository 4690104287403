import PropTypes from 'prop-types';
import { useEffect, useCallback, useState, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
//
import { useAuthContext } from '../hooks';

function PermissionGuard({ children }) {
    const router = useRouter();

    const { permissions } = useAuthContext();
    console.log('permissions', permissions);

    const pathsWithPermissions = useMemo(
        () => ({
            // site admin users
            [paths.dashboard.user.root]: permissions?.SiteAdministration?.UsersChild?.read,
            [paths.dashboard.user.new]: permissions?.SiteAdministration?.UsersChild?.write,
            [paths.dashboard.user.list]: permissions?.SiteAdministration?.UsersChild?.read,
            [paths.dashboard.user.cards]: permissions?.SiteAdministration?.UsersChild?.edit,
            [paths.dashboard.user.profile]: permissions?.SiteAdministration?.UsersChild?.read,
            [paths.dashboard.user.account]: permissions?.SiteAdministration?.UsersChild?.edit,
            [paths.dashboard.user.edit]: permissions?.SiteAdministration?.UsersChild?.edit,
            [paths.dashboard.user.demo]: permissions?.SiteAdministration?.UsersChild?.edit,

            // site admin roles
            [paths.dashboard.roles.root]: permissions?.SiteAdministration?.RolesChild?.read,
            [paths.dashboard.roles.list]: permissions?.SiteAdministration?.RolesChild?.read,
            [paths.dashboard.roles.edit]: permissions?.SiteAdministration?.RolesChild?.edit,
            [paths.dashboard.roles.create]: permissions?.SiteAdministration?.RolesChild?.write,

            // site admin permissions
            [paths.dashboard.permissions.root]:
                permissions?.SiteAdministration?.PermissionsChild?.read,
            [paths.dashboard.permissions.list]:
                permissions?.SiteAdministration?.PermissionsChild?.read,
            [paths.dashboard.permissions.edit]:
                permissions?.SiteAdministration?.PermissionsChild?.edit,
            [paths.dashboard.permissions.create]:
                permissions?.SiteAdministration?.PermissionsChild?.write,

            // site admin organizarion
            [paths.dashboard.organisation.root]: permissions?.UEMSettings?.OrganisationChild?.read,
            [paths.dashboard.organisation.edit]: permissions?.UEMSettings?.OrganisationChild?.edit,

            // site admin enterprise
            [paths.dashboard.enterprises.root]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.read,
            [paths.dashboard.enterprises.list]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.read,
            [paths.dashboard.enterprises.edit]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.edit,
            [paths.dashboard.enterprises.create]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.write,
            [paths.dashboard.enterprises.details]:
                permissions?.UEMSettings?.EnterprisesDivisionsChild?.read,

            // teamxcell administrative-boundary
            [paths.dashboard.teamxcelgeofence.root]:
                permissions?.UEMSettings?.AdministrativeBoundaryChild?.read,
            [paths.dashboard.teamxcelgeofence.list]:
                permissions?.UEMSettings?.AdministrativeBoundaryChild?.read,
            [paths.dashboard.teamxcelgeofence.create]:
                permissions?.UEMSettings?.AdministrativeBoundaryChild?.write,

            // teamxcell Reports
            [paths.dashboard.teamxcelreports.root]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.projectreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.absentreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.employeereport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.teamreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,
            [paths.dashboard.teamxcelreports.clockinoutreport]:
                permissions?.TeamXCEL?.TeamxcelReportsChild?.read,

            // teamxcell Employee
            [paths.dashboard.employee.root]: permissions?.TeamXCEL?.EmployeeChild?.read,
            [paths.dashboard.employee.list]: permissions?.TeamXCEL?.EmployeeChild?.read,
            [paths.dashboard.employee.edit]: permissions?.TeamXCEL?.EmployeeChild?.edit,
            [paths.dashboard.employee.create]: permissions?.TeamXCEL?.EmployeeChild?.write,

            // teamxcell Enteprise Project
            [paths.dashboard.enterpriseproject.root]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.read,
            [paths.dashboard.enterpriseproject.list]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.read,
            [paths.dashboard.enterpriseproject.edit]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.edit,
            [paths.dashboard.enterpriseproject.create]:
                permissions?.TeamXCEL?.EnterpriseProjectChild?.write,

            // teamxcell Office
            [paths.dashboard.office.root]: permissions?.TeamXCEL?.OfficeChild?.read,
            [paths.dashboard.office.list]: permissions?.TeamXCEL?.OfficeChild?.read,
            [paths.dashboard.office.edit]: permissions?.TeamXCEL?.OfficeChild?.edit,
            [paths.dashboard.office.create]: permissions?.TeamXCEL?.OfficeChild?.write,

            // teamxcell Project Assignment
            [paths.dashboard.projectteams.root]: permissions?.TeamXCEL?.ProjectTeamsChild?.read,
            [paths.dashboard.projectteams.list]: permissions?.TeamXCEL?.ProjectTeamsChild?.read,
            [paths.dashboard.projectteams.edit]: permissions?.TeamXCEL?.ProjectTeamsChild?.edit,
            [paths.dashboard.projectteams.create]: permissions?.TeamXCEL?.ProjectTeamsChild?.write,

            // teamxcell Dashboard
            [paths.dashboard.teamxceldashboard.root]:
                permissions?.TeamXCEL?.TeamxcelDashboardChild?.read,
        }),
        [permissions]
    );

    useEffect(() => {
        const currentPath = window.location.pathname;
        const pathFromDashboard = currentPath.slice(currentPath.indexOf('/dashboard'));
        const requiredPermission = pathsWithPermissions?.[pathFromDashboard];
        console.log('re', requiredPermission);
        if (requiredPermission === false || requiredPermission === null) {
            router.replace(paths.page403);
        }
    }, [permissions, router, pathsWithPermissions]);

    console.log('paths', window.location.href);

    return <>{children}</>;
}

export default PermissionGuard;

PermissionGuard.propTypes = {
    children: PropTypes.node,
};
