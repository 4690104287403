import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
import Label from 'src/components/label';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';
// ----------------------------------------------------------------------

const EDIT = 'edit';
const WRITE = 'write';
const READ = 'read';
const DELETE = 'delete';

export function useNavData() {
    const { t } = useLocales();
    const { permissions } = useAuthContext();
    console.log('permissions', permissions);

    const data = useMemo(() => {
        const PermissionsMapper = (childern, parent) =>
            childern?.map((item) => ({ ...item, permission: parent[item.permissionType] }));
        return [
            {
                items: PermissionsMapper(
                    [
                        {
                            title: t('Dashboards'),
                            permissionType: 'TeamxcelDashboard',
                            icon: <Iconify icon="solar:pie-chart-2-bold-duotone" />,
                            path: paths.dashboard.teamxceldashboard.root,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Attendance Dashboard'),
                                        path: paths.dashboard.teamxceldashboard.root,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.TeamxcelDashboardChild
                            ),
                        },
                    ],
                    permissions?.TeamXCEL
                ),
            },

            {
                icon: 'solar:settings-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Offices'),
                            permissionType: 'Office',
                            path: paths.dashboard.office.root,
                            icon: <Iconify icon="solar:buildings-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Office Hierarchy'),
                                        path: paths.dashboard.office.root,
                                        permissionType: READ,
                                    },
                                    {
                                        title: t('Create'),
                                        path: paths.dashboard.office.create,
                                        permissionType: WRITE,
                                    },

                                    {
                                        title: t('List'),
                                        path: paths.dashboard.office.list,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.OfficeChild
                            ),
                        },
                        {
                            title: t('Employees'),
                            permissionType: 'TeamXCEL',
                            icon: <Iconify icon="solar:user-bold-duotone" />,
                            path: paths.dashboard.employee.root,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('List'),
                                        path: paths.dashboard.employee.list,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.EmployeeChild
                            ),
                        },

                        {
                            title: t('Enterprise Projects'),
                            permissionType: 'TeamXCEL',
                            icon: <Iconify icon="solar:case-minimalistic-bold-duotone" />,
                            path: paths.dashboard.enterpriseproject.root,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Create'),
                                        path: paths.dashboard.enterpriseproject.create,
                                        permissionType: WRITE,
                                    },

                                    {
                                        title: t('List'),
                                        path: paths.dashboard.enterpriseproject.list,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.EnterpriseProjectChild
                            ),
                        },
                        {
                            title: t('Project Teams'),
                            permissionType: 'TeamXCEL',
                            icon: <Iconify icon="solar:users-group-two-rounded-bold-duotone" />,
                            path: paths.dashboard.projectteams.root,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Create'),
                                        path: paths.dashboard.projectteams.create,
                                        permissionType: WRITE,
                                    },
                                    {
                                        title: t('List'),
                                        path: paths.dashboard.projectteams.list,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.ProjectTeamsChild
                            ),
                        },
                        {
                            title: t('Geo Fencing'),
                            permissionType: 'TeamXCEL',
                            icon: <Iconify icon="solar:point-on-map-bold-duotone" />,
                            path: paths.dashboard.teamxcelgeofence.root,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Create'),
                                        path: paths.dashboard.teamxcelgeofence.create,
                                        permissionType: WRITE,
                                    },
                                    {
                                        title: t('List'),
                                        path: paths.dashboard.teamxcelgeofence.list,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.ProjectTeamsChild
                            ),
                        },

                        {
                            title: t('Project Assignments'),
                            permissionType: 'TeamXCEL',
                            icon: <Iconify icon="solar:document-medicine-bold-duotone" />,
                            path: paths.dashboard.employee.projectassign,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Create'),
                                        path: paths.dashboard.employee.projectassign,
                                        permissionType: READ,
                                    },
                                ],
                                permissions?.TeamXCEL?.EmployeeChild
                            ),
                        },

                        {
                            title: t('Reports'),
                            permissionType: 'TeamXCEL',
                            icon: <Iconify icon="solar:documents-bold-duotone" />,
                            path: paths.dashboard.teamxcelreports.root,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Employee Reports'),
                                        path: paths.dashboard.teamxcelreports.employeereport,
                                        permissionType: READ,
                                    },
                                    {
                                        title: t('Team Reports'),
                                        path: paths.dashboard.teamxcelreports.teamreport,
                                        permissionType: WRITE,
                                    },
                                    {
                                        title: t('Project Reports'),
                                        path: paths.dashboard.teamxcelreports.projectreport,
                                        permissionType: EDIT,
                                    },
                                    {
                                        title: t('Override Reports'),
                                        path: paths.dashboard.teamxcelreports.overridereport,
                                        permissionType: EDIT,
                                    },

                                    {
                                        title: t('Clockin/out Reports'),
                                        path: paths.dashboard.teamxcelreports.clockinoutreport,
                                        permissionType: WRITE,
                                    },
                                ],
                                permissions?.TeamXCEL?.TeamxcelReportsChild
                            ),
                        },
                    ],
                    permissions?.TeamXCEL
                ),
            },
            {
                items: PermissionsMapper(
                    [
                        {
                            title: t('Site Administration'),
                            path: 'dashboard/site-administration',
                            permissionType: 'SiteAdministration',
                            icon: <Iconify icon="solar:user-id-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Users'),
                                        path: paths.dashboard.user.root,
                                        permissionType: 'Users',
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.user.list,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.user.new,
                                                    permissionType: WRITE,
                                                },
                                                {
                                                    title: t('User Cards'),
                                                    path: paths.dashboard.user.cards,
                                                    permissionType: EDIT,
                                                },
                                            ],
                                            permissions?.SiteAdministration?.UsersChild
                                        ),
                                    },

                                    {
                                        title: t('Roles'),
                                        path: paths.dashboard.roles.root,
                                        permissionType: 'Roles',
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Role Cards'),
                                                    path: paths.dashboard.roles.root,
                                                    permissionType: READ,
                                                },
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.roles.list,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.roles.create,
                                                    permissionType: WRITE,
                                                },
                                            ],
                                            permissions?.SiteAdministration?.RolesChild
                                        ),
                                    },

                                    {
                                        title: t('Permissions'),
                                        permissionType: 'Permissions',
                                        path: paths.dashboard.permissions.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.permissions.list,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.SiteAdministration?.PermissionsChild
                                        ),
                                    },
                                ],
                                permissions?.SiteAdministration
                            ),
                        },
                    ],
                    permissions?.SiteAdministration
                ),
            },

            // UEM Settings
            // ----------------------------------------------------------------------
            {
                // subheader: t('UEM Settings'),
                icon: 'solar:settings-bold-duotone',
                items: PermissionsMapper(
                    [
                        {
                            title: t('Settings'),
                            permissionType: 'UEMSettings',
                            path: 'dashboard/uem-setting',
                            icon: <Iconify icon="solar:settings-bold-duotone" />,
                            children: PermissionsMapper(
                                [
                                    {
                                        title: t('Organisation'),
                                        permissionType: 'Organisation',
                                        path: paths.dashboard.organisation.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('Details'),
                                                    path: paths.dashboard.organisation.root,
                                                    permissionType: READ,
                                                },
                                            ],
                                            permissions?.UEMSettings?.OrganisationChild
                                        ),
                                    },

                                    {
                                        title: t('Enterprises'),
                                        permissionType: 'EnterprisesDivisions',
                                        path: paths.dashboard.enterprises.root,
                                        children: PermissionsMapper(
                                            [
                                                {
                                                    title: t('List'),
                                                    path: paths.dashboard.enterprises.list,
                                                    permissionType: EDIT,
                                                },
                                                {
                                                    title: t('Create'),
                                                    path: paths.dashboard.enterprises.create,
                                                    permissionType: WRITE,
                                                },
                                            ],
                                            permissions?.UEMSettings?.EnterprisesDivisionsChild
                                        ),
                                    },
                                ],
                                permissions?.UEMSettings
                            ),
                        },
                    ],
                    permissions?.UEMSettings
                ),
            },
        ];
    }, [t, permissions]);

    return data;
}
