import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';
import { LoadingScreen } from 'src/components/loading-screen';
import PermissionGuard from 'src/auth/guard/permission-guard';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/site-administration/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/site-administration/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/site-administration/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/site-administration/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/site-administration/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/site-administration/user/edit'));
// Roll
const RoleListPage = lazy(() => import('src/pages/dashboard/site-administration/roles/list'));
const RoleCreatePage = lazy(() => import('src/pages/dashboard/site-administration/roles/create'));
const RoleEditPage = lazy(() => import('src/pages/dashboard/site-administration/roles/edit'));
const RoleDetailsPage = lazy(() => import('src/pages/dashboard/site-administration/roles/details'));
// Permissions
const PermissionsListPage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/list')
);
const PermissionsCreatePage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/create')
);
const PermissionsEditPage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/edit')
);
const PermissionsDetailsPage = lazy(() =>
    import('src/pages/dashboard/site-administration/permissions/details')
);
// Organisation
const OrganisationEditPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/organisation/edit')
);
const OrganisationDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/organisation/details')
);
// Enterprises
const EnterprisesListPage = lazy(() => import('src/pages/dashboard/uem-setting/enterprises/list'));
const EnterprisesCreatePage = lazy(() =>
    import('src/pages/dashboard/uem-setting/enterprises/create')
);

const EnterprisesEditPage = lazy(() => import('src/pages/dashboard/uem-setting/enterprises/edit'));
const EnterprisesDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/enterprises/details')
);
// OrganisationHierarchy
const OrganisationHierarchyListPage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/list')
);
const OrganisationHierarchyCreatePage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/create')
);
const OrganisationHierarchyEditPage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/edit')
);
const OrganisationHierarchyDetailsPage = lazy(() =>
    import('src/pages/dashboard/site-administration/organisationhierarchy/details')
);

// termsofusedoc
const TermsOfUseDocListPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/list')
);
const TermsOfUseDocCreatePage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/create')
);
const TermsOfUseDocEditPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/edit')
);
const TermsOfUseDocDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/terms-of-use-doc/details')
);

const ReportsPage = lazy(() => import('src/pages/dashboard/reports/report'));
const ToolsRemoteControlPage = lazy(() => import('src/pages/dashboard/tools/remote-control'));
const ToolsNotificationPage = lazy(() => import('src/pages/dashboard/tools/notification'));

// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// Team XCELL

const AdministrativeBoundaryDetailsPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/administrative-boundary/details')
);
const AdministrativeBoundaryCreatePage = lazy(() =>
    import('src/pages/dashboard/uem-setting/administrative-boundary/create')
);
const AdministrativeBoundaryListPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/administrative-boundary/list')
);
const AdministrativeBoundaryEditPage = lazy(() =>
    import('src/pages/dashboard/uem-setting/administrative-boundary/edit')
);
const EmployeeReportsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-reports/employee_reports')
);
const ProjectReportsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-reports/project_reports')
);
const OverrideReportsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-reports/override_reports')
);
const TeamReportsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-reports/team_reports')
);
const AbsentReportsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-reports/absent_reports')
);

const ClockinoutReportsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-reports/clockin_outreports')
);

const EmployeeDetailsPage = lazy(() => import('src/pages/dashboard/team-xcel/employee/details'));
const EmployeeListPage = lazy(() => import('src/pages/dashboard/team-xcel/employee/list'));

const EmployeeCreatePage = lazy(() => import('src/pages/dashboard/team-xcel/employee/create'));
const EmployeeEditPage = lazy(() => import('src/pages/dashboard/team-xcel/employee/edit'));
const EmployeeProjectAssignPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/employee/projectassign')
);
const EmployeeEditProjectAssignPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/employee/editprojectassign')
);

const EnterpriseProjectDetailsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/enterprise-project/details')
);
const EnterpriseProjectListPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/enterprise-project/list')
);
const EnterpriseProjectEmployeePage = lazy(() =>
    import('src/pages/dashboard/team-xcel/enterprise-project/employee')
);
const EnterpriseProjectCreatePage = lazy(() =>
    import('src/pages/dashboard/team-xcel/enterprise-project/create')
);
const EnterpriseProjectEditPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/enterprise-project/edit')
);
const OfficeDetailsPage = lazy(() => import('src/pages/dashboard/team-xcel/office/details'));
const OfficeListPage = lazy(() => import('src/pages/dashboard/team-xcel/office/list'));
const OfficeCreatePage = lazy(() => import('src/pages/dashboard/team-xcel/office/create'));
const OfficeEditPage = lazy(() => import('src/pages/dashboard/team-xcel/office/edit'));
const ProjectAssignmentDetailsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/project-teams/details')
);

const ProjectAssignmentListPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/project-teams/list')
);
const ProjectAssignmentCreatePage = lazy(() =>
    import('src/pages/dashboard/team-xcel/project-teams/create')
);
const ProjectAssignmentEditPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/project-teams/edit')
);
const ProjectAssignmentAssociateProject = lazy(() =>
    import('src/pages/dashboard/team-xcel/project-teams/associate')
);
const ProjectEmployeeRegistrationDetailsPage = lazy(() =>
    import('src/pages/dashboard/team-xcel/teamxcel-dashboard/details')
);

// Team XCELL geo fencing

const XcelGeoFencingCreate = lazy(() =>
    import('src/pages/dashboard/team-xcel/location_management/create')
);

const XcelGeoFencingCards = lazy(() =>
    import('src/pages/dashboard/team-xcel/location_management/cards')
);

// const ProjectEmployeeRegistrationListPage = lazy(() =>
//     import('src/pages/dashboard/team-xcel/teamxcel-dashboard/list')
// );
// const ProjectEmployeeRegistrationCreatePage = lazy(() =>
//     import('src/pages/dashboard/team-xcel/dashboard-and-reports/create')
// );
// const ProjectEmployeeRegistrationEditPage = lazy(() =>
//     import('src/pages/dashboard/team-xcel/dashboard-and-reports/edit')
// );

// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <PermissionGuard>
                    <DashboardLayout>
                        <Suspense fallback={<LoadingScreen />}>
                            <Outlet />
                        </Suspense>
                    </DashboardLayout>
                </PermissionGuard>
            </AuthGuard>
        ),
        children: [
            { element: <IndexPage />, index: true },
            { path: 'ecommerce', element: <OverviewEcommercePage /> },
            { path: 'analytics', element: <OverviewAnalyticsPage /> },
            { path: 'banking', element: <OverviewBankingPage /> },
            { path: 'booking', element: <OverviewBookingPage /> },
            { path: 'file', element: <OverviewFilePage /> },
            {
                path: 'site-administration/user',
                children: [
                    { element: <UserProfilePage />, index: true },
                    { path: ':id/profile', element: <UserProfilePage /> },
                    { path: 'cards', element: <UserCardsPage /> },
                    { path: 'list', element: <UserListPage /> },
                    { path: 'new', element: <UserCreatePage /> },
                    { path: 'edit', element: <UserEditPage /> },
                    { path: 'account', element: <UserAccountPage /> },
                ],
            },
            {
                path: 'site-administration/roles',
                children: [
                    { element: <RoleDetailsPage />, index: true },
                    { path: 'list', element: <RoleListPage /> },
                    { path: 'create', element: <RoleCreatePage /> },
                    { path: 'edit', element: <RoleEditPage /> },
                ],
            },
            {
                path: 'site-administration/permissions',
                children: [
                    { element: <PermissionsDetailsPage />, index: true },
                    { path: 'list', element: <PermissionsListPage /> },
                    { path: 'create', element: <PermissionsCreatePage /> },
                    { path: 'edit', element: <PermissionsEditPage /> },
                ],
            },
            {
                path: 'uem-setting/organisation',
                children: [
                    { element: <OrganisationDetailsPage />, index: true },
                    { path: 'edit', element: <OrganisationEditPage /> },
                ],
            },
            {
                path: 'uem-setting/enterprises',
                children: [
                    { element: <EnterprisesDetailsPage />, index: true },
                    { path: 'list', element: <EnterprisesListPage /> },
                    { path: 'details/:id', element: <EnterprisesDetailsPage /> },
                    { path: 'create', element: <EnterprisesCreatePage /> },
                    { path: 'create/:id', element: <EnterprisesCreatePage /> },
                    { path: 'edit/:id', element: <EnterprisesEditPage /> },
                ],
            },
            {
                path: 'uem-setting/organisation-hierarchy',
                children: [
                    { element: <OrganisationHierarchyDetailsPage />, index: true },
                    { path: 'list', element: <OrganisationHierarchyListPage /> },
                    { path: 'create', element: <OrganisationHierarchyCreatePage /> },
                    { path: 'edit', element: <OrganisationHierarchyEditPage /> },
                ],
            },

            {
                path: 'uem-setting/terms-of-use-doc',
                children: [
                    { element: <TermsOfUseDocDetailsPage />, index: true },
                    { path: 'list', element: <TermsOfUseDocListPage /> },
                    { path: 'create', element: <TermsOfUseDocCreatePage /> },
                    { path: 'edit', element: <TermsOfUseDocEditPage /> },
                ],
            },

            {
                path: 'reports',
                children: [{ element: <ReportsPage />, index: true }],
            },

            {
                path: 'tools',
                children: [
                    { element: <ToolsRemoteControlPage />, index: true },
                    { path: 'notification', element: <ToolsNotificationPage /> },
                ],
            },

            // Teamxcel
            {
                path: 'team-xcel/teamxcel-reports',
                children: [
                    { element: <EmployeeReportsPage />, index: true },
                    { path: 'projectreport', element: <ProjectReportsPage /> },
                    { path: 'overridereport', element: <OverrideReportsPage /> },
                    { path: 'teamreport', element: <TeamReportsPage /> },
                    { path: 'employeereport', element: <EmployeeReportsPage /> },
                    { path: 'absentreport', element: <AbsentReportsPage /> },
                    { path: 'clockinoutreport', element: <ClockinoutReportsPage /> },
                ],
            },
            {
                path: 'team-xcel/employee',
                children: [
                    { element: <EmployeeDetailsPage />, index: true },
                    { path: 'list', element: <EmployeeListPage /> },
                    { path: 'create', element: <EmployeeCreatePage /> },
                    { path: 'edit', element: <EmployeeEditPage /> },
                    { path: 'projectassign', element: <EmployeeProjectAssignPage /> },
                    { path: 'editprojectassign', element: <EmployeeEditProjectAssignPage /> },
                ],
            },
            {
                path: 'team-xcel/enterprise-project',
                children: [
                    { element: <EnterpriseProjectDetailsPage />, index: true },
                    { path: 'list', element: <EnterpriseProjectListPage /> },
                    { path: 'employee', element: <EnterpriseProjectEmployeePage /> },
                    { path: 'create', element: <EnterpriseProjectCreatePage /> },
                    { path: 'edit', element: <EnterpriseProjectEditPage /> },
                ],
            },
            {
                path: 'team-xcel/office',
                children: [
                    { element: <OfficeDetailsPage />, index: true },
                    { path: 'list', element: <OfficeListPage /> },
                    { path: 'create', element: <OfficeCreatePage /> },
                    { path: 'edit', element: <OfficeEditPage /> },
                ],
            },
            {
                path: 'team-xcel/project-teams',
                children: [
                    { element: <ProjectAssignmentDetailsPage />, index: true },
                    { path: 'list', element: <ProjectAssignmentListPage /> },
                    { path: 'create', element: <ProjectAssignmentCreatePage /> },
                    { path: 'edit', element: <ProjectAssignmentEditPage /> },
                    { path: 'associate', element: <ProjectAssignmentAssociateProject /> },
                ],
            },
            {
                path: 'team-xcel/geo-fence',
                children: [
                    { element: <XcelGeoFencingCreate />, index: true },
                    { path: 'list', element: <XcelGeoFencingCards /> },
                    { path: 'create', element: <XcelGeoFencingCreate /> },
                ],
            },
            {
                path: 'team-xcel/teamxcel-dashboard',
                children: [
                    { element: <ProjectEmployeeRegistrationDetailsPage />, index: true },
                    // { path: 'reports', element: <ProjectEmployeeRegistrationListPage /> },
                    // { path: 'create', element: <ProjectEmployeeRegistrationCreatePage /> },
                    // { path: 'edit', element: <ProjectEmployeeRegistrationEditPage /> },
                ],
            },

            {
                path: 'product',
                children: [
                    { element: <ProductListPage />, index: true },
                    { path: 'list', element: <ProductListPage /> },
                    { path: ':id', element: <ProductDetailsPage /> },
                    { path: 'new', element: <ProductCreatePage /> },
                    { path: ':id/edit', element: <ProductEditPage /> },
                ],
            },
            {
                path: 'order',
                children: [
                    { element: <OrderListPage />, index: true },
                    { path: 'list', element: <OrderListPage /> },
                    { path: ':id', element: <OrderDetailsPage /> },
                ],
            },
            {
                path: 'invoice',
                children: [
                    { element: <InvoiceListPage />, index: true },
                    { path: 'list', element: <InvoiceListPage /> },
                    { path: ':id', element: <InvoiceDetailsPage /> },
                    { path: ':id/edit', element: <InvoiceEditPage /> },
                    { path: 'new', element: <InvoiceCreatePage /> },
                ],
            },
            {
                path: 'post',
                children: [
                    { element: <BlogPostsPage />, index: true },
                    { path: 'list', element: <BlogPostsPage /> },
                    { path: ':title', element: <BlogPostPage /> },
                    { path: ':title/edit', element: <BlogEditPostPage /> },
                    { path: 'new', element: <BlogNewPostPage /> },
                ],
            },
            {
                path: 'job',
                children: [
                    { element: <JobListPage />, index: true },
                    { path: 'list', element: <JobListPage /> },
                    { path: ':id', element: <JobDetailsPage /> },
                    { path: 'new', element: <JobCreatePage /> },
                    { path: ':id/edit', element: <JobEditPage /> },
                ],
            },
            {
                path: 'tour',
                children: [
                    { element: <TourListPage />, index: true },
                    { path: 'list', element: <TourListPage /> },
                    { path: ':id', element: <TourDetailsPage /> },
                    { path: 'new', element: <TourCreatePage /> },
                    { path: ':id/edit', element: <TourEditPage /> },
                ],
            },
            { path: 'file-manager', element: <FileManagerPage /> },
            { path: 'mail', element: <MailPage /> },
            { path: 'chat', element: <ChatPage /> },
            { path: 'calendar', element: <CalendarPage /> },
            { path: 'kanban', element: <KanbanPage /> },
            { path: 'permission', element: <PermissionDeniedPage /> },
            { path: 'blank', element: <BlankPage /> },
        ],
    },
];
