import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    const logo = (
        <Box
            ref={ref}
            component="div"
            sx={{
                width: 175,
                height: 42,
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 843 225"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.462059 32.3678L0.924106 50.7356L32.3534 51.1797L63.7827 51.6221V130.773V209.923H81.7423H99.7019V130.737V51.5519H131.539H163.377V36.6324V21.7145L167.867 27.4681C170.335 30.6338 173.459 34.8413 174.806 36.8185C176.153 38.7957 184.235 49.9927 192.765 61.7007C201.296 73.4088 210.387 86.0164 212.967 89.7193C215.546 93.4223 220.146 99.8649 223.187 104.04L228.717 111.627L193.598 159.971C174.283 186.561 158.479 208.677 158.479 209.12C158.479 209.561 168.721 209.923 181.24 209.923H204.002L221.649 185.594C231.356 172.213 240.399 159.654 241.746 157.685C247.043 149.946 250.778 145.058 251.866 144.444C252.857 143.884 259.938 152.622 267.869 164.191C269.216 166.157 277.169 177.25 285.543 188.843L300.766 209.923H323.503C336.008 209.923 346.238 209.533 346.238 209.054C346.238 208.578 330.449 186.442 311.15 159.865L276.062 111.542L282.085 103.179C285.398 98.5816 289.249 93.2508 290.642 91.3341C292.036 89.4173 294.278 86.2531 295.625 84.3037C296.972 82.3543 303.632 73.2683 310.425 64.1138C317.217 54.9593 326.336 42.3272 330.685 36.0413C335.036 29.7555 340.403 22.2239 342.611 19.3062L346.625 14L323.167 14.049L299.707 14.098L281.747 39.3753C258.685 71.8348 259.456 70.7719 255.596 75.5165L252.303 79.5673L233.555 53.7691C223.243 39.581 212.717 24.828 210.163 20.9863L205.52 14H102.76H0L0.462059 32.3678Z"
                    fill={PRIMARY_DARK}
                />
                <path
                    d="M461.554 87.24V41.944H447V34.04H484.572V41.944H469.573V87.24H461.554Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M503.326 88C499.316 88 495.752 87.1387 492.634 85.416C489.564 83.6427 487.139 81.236 485.357 78.196C483.624 75.156 482.758 71.66 482.758 67.708C482.758 64.5667 483.253 61.704 484.243 59.12C485.233 56.536 486.594 54.3067 488.327 52.432C490.109 50.5067 492.213 49.0373 494.638 48.024C497.114 46.96 499.787 46.428 502.658 46.428C505.183 46.428 507.534 46.9347 509.712 47.948C511.89 48.9107 513.771 50.2533 515.355 51.976C516.989 53.6987 518.226 55.7507 519.068 58.132C519.959 60.4627 520.38 63.0213 520.33 65.808L520.256 69.152H488.401L486.693 62.92H513.647L512.534 64.212V62.388C512.385 60.716 511.841 59.2213 510.9 57.904C509.96 56.5867 508.771 55.548 507.336 54.788C505.9 54.028 504.341 53.648 502.658 53.648C499.985 53.648 497.732 54.18 495.901 55.244C494.069 56.2573 492.683 57.7773 491.743 59.804C490.802 61.78 490.332 64.2373 490.332 67.176C490.332 69.9627 490.901 72.3947 492.04 74.472C493.178 76.4987 494.787 78.0693 496.866 79.184C498.945 80.2987 501.346 80.856 504.069 80.856C505.999 80.856 507.781 80.5267 509.415 79.868C511.098 79.2093 512.905 78.0187 514.836 76.296L518.697 81.844C517.509 83.06 516.048 84.124 514.316 85.036C512.633 85.948 510.826 86.6827 508.895 87.24C507.014 87.7467 505.158 88 503.326 88Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M543.641 88C540.424 88 537.478 87.088 534.805 85.264C532.182 83.44 530.078 80.9573 528.494 77.816C526.91 74.6747 526.117 71.128 526.117 67.176C526.117 63.1733 526.91 59.6267 528.494 56.536C530.127 53.3947 532.305 50.9373 535.028 49.164C537.8 47.3907 540.894 46.504 544.31 46.504C546.339 46.504 548.196 46.808 549.879 47.416C551.562 48.024 553.022 48.8853 554.26 50C555.547 51.064 556.586 52.3053 557.378 53.724C558.22 55.1427 558.74 56.6627 558.938 58.284L557.23 57.676V47.34H564.952V87.24H557.23V77.74L559.012 77.208C558.715 78.576 558.096 79.9187 557.156 81.236C556.265 82.5027 555.101 83.6427 553.666 84.656C552.28 85.6693 550.72 86.48 548.988 87.088C547.305 87.696 545.523 88 543.641 88ZM545.646 80.78C547.973 80.78 550.027 80.1973 551.809 79.032C553.591 77.8667 554.978 76.2707 555.968 74.244C557.007 72.1667 557.527 69.8107 557.527 67.176C557.527 64.592 557.007 62.2867 555.968 60.26C554.978 58.2333 553.591 56.6373 551.809 55.472C550.027 54.3067 547.973 53.724 545.646 53.724C543.369 53.724 541.34 54.3067 539.557 55.472C537.825 56.6373 536.439 58.2333 535.399 60.26C534.409 62.2867 533.914 64.592 533.914 67.176C533.914 69.8107 534.409 72.1667 535.399 74.244C536.439 76.2707 537.825 77.8667 539.557 79.032C541.34 80.1973 543.369 80.78 545.646 80.78Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M575.482 87.24V47.34H583.205V55.852L581.794 56.764C582.19 55.4467 582.809 54.18 583.65 52.964C584.541 51.748 585.606 50.684 586.843 49.772C588.13 48.8093 589.492 48.0493 590.927 47.492C592.412 46.9347 593.922 46.656 595.457 46.656C597.684 46.656 599.64 47.036 601.323 47.796C603.006 48.556 604.392 49.696 605.481 51.216C606.57 52.736 607.362 54.636 607.857 56.916L606.669 56.612L607.189 55.32C607.733 54.1547 608.476 53.0653 609.416 52.052C610.406 50.988 611.52 50.0507 612.758 49.24C613.995 48.4293 615.307 47.796 616.693 47.34C618.079 46.884 619.441 46.656 620.777 46.656C623.698 46.656 626.099 47.264 627.98 48.48C629.91 49.696 631.346 51.5453 632.287 54.028C633.277 56.5107 633.772 59.6013 633.772 63.3V87.24H626.049V63.756C626.049 61.476 625.752 59.6267 625.158 58.208C624.614 56.7387 623.772 55.6493 622.634 54.94C621.495 54.2307 620.035 53.876 618.253 53.876C616.867 53.876 615.555 54.1293 614.317 54.636C613.129 55.092 612.09 55.7507 611.199 56.612C610.307 57.4733 609.614 58.4867 609.119 59.652C608.624 60.7667 608.377 62.008 608.377 63.376V87.24H600.654V63.604C600.654 61.5267 600.357 59.7787 599.763 58.36C599.169 56.8907 598.303 55.776 597.165 55.016C596.026 54.256 594.64 53.876 593.006 53.876C591.62 53.876 590.333 54.1293 589.145 54.636C587.957 55.092 586.917 55.7507 586.026 56.612C585.135 57.4227 584.442 58.4107 583.947 59.576C583.452 60.6907 583.205 61.9067 583.205 63.224V87.24H575.482Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M677.574 87.24L661.461 63.376L640.818 34.04H650.991L666.733 57.6L687.747 87.24H677.574ZM640.447 87.24L660.273 58.284L665.248 64.288L649.877 87.24H640.447ZM667.921 62.54L663.02 56.764L677.574 34.04H687.004L667.921 62.54Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M709.516 88C705.903 88 702.66 87.088 699.789 85.264C696.968 83.44 694.715 80.9573 693.032 77.816C691.398 74.6747 690.582 71.1533 690.582 67.252C690.582 63.3507 691.398 59.8293 693.032 56.688C694.715 53.5467 696.968 51.064 699.789 49.24C702.66 47.416 705.903 46.504 709.516 46.504C712.982 46.504 716.125 47.2387 718.947 48.708C721.818 50.1267 723.996 52.0773 725.481 54.56L721.249 59.88C720.456 58.7147 719.442 57.6507 718.204 56.688C716.967 55.7253 715.655 54.9653 714.269 54.408C712.883 53.8507 711.546 53.572 710.259 53.572C707.883 53.572 705.754 54.18 703.873 55.396C702.042 56.5613 700.581 58.1827 699.492 60.26C698.403 62.3373 697.859 64.668 697.859 67.252C697.859 69.836 698.428 72.1667 699.566 74.244C700.705 76.2707 702.215 77.892 704.096 79.108C705.977 80.324 708.056 80.932 710.333 80.932C711.67 80.932 712.957 80.704 714.194 80.248C715.481 79.792 716.719 79.108 717.907 78.196C719.095 77.284 720.209 76.1693 721.249 74.852L725.481 80.172C723.897 82.452 721.62 84.3267 718.65 85.796C715.729 87.2653 712.685 88 709.516 88Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M750.887 88C746.878 88 743.314 87.1387 740.195 85.416C737.126 83.6427 734.7 81.236 732.918 78.196C731.185 75.156 730.319 71.66 730.319 67.708C730.319 64.5667 730.814 61.704 731.804 59.12C732.794 56.536 734.156 54.3067 735.888 52.432C737.67 50.5067 739.774 49.0373 742.2 48.024C744.675 46.96 747.348 46.428 750.219 46.428C752.744 46.428 755.095 46.9347 757.273 47.948C759.451 48.9107 761.332 50.2533 762.917 51.976C764.55 53.6987 765.788 55.7507 766.629 58.132C767.52 60.4627 767.941 63.0213 767.892 65.808L767.817 69.152H735.962L734.255 62.92H761.209L760.095 64.212V62.388C759.946 60.716 759.402 59.2213 758.461 57.904C757.521 56.5867 756.333 55.548 754.897 54.788C753.462 54.028 751.902 53.648 750.219 53.648C747.546 53.648 745.294 54.18 743.462 55.244C741.63 56.2573 740.244 57.7773 739.304 59.804C738.363 61.78 737.893 64.2373 737.893 67.176C737.893 69.9627 738.462 72.3947 739.601 74.472C740.739 76.4987 742.348 78.0693 744.427 79.184C746.506 80.2987 748.907 80.856 751.63 80.856C753.561 80.856 755.343 80.5267 756.976 79.868C758.659 79.2093 760.466 78.0187 762.397 76.296L766.258 81.844C765.07 83.06 763.61 84.124 761.877 85.036C760.194 85.948 758.387 86.6827 756.456 87.24C754.575 87.7467 752.719 88 750.887 88Z"
                    fill={PRIMARY_MAIN}
                />
                <path d="M776.352 87.24V31H784V87.24H776.352Z" fill={PRIMARY_MAIN} />
                <path
                    d="M392 0.999995C392 0.44771 392.448 0 393 0H401C401.552 0 402 0.447715 402 1V224C402 224.552 401.552 225 401 225H393C392.448 225 392 224.552 392 224V0.999995Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M458.859 138C457.152 138 455.565 137.773 454.097 137.32C452.66 136.867 451.342 136.187 450.144 135.28C448.976 134.343 447.928 133.195 447 131.835L450.324 127.981C451.791 130.097 453.199 131.563 454.546 132.379C455.894 133.195 457.496 133.603 459.353 133.603C460.491 133.603 461.524 133.421 462.452 133.059C463.381 132.696 464.114 132.197 464.653 131.563C465.192 130.928 465.462 130.203 465.462 129.387C465.462 128.843 465.372 128.329 465.192 127.845C465.013 127.362 464.728 126.924 464.339 126.531C463.979 126.138 463.5 125.775 462.901 125.443C462.332 125.11 461.659 124.823 460.88 124.581C460.101 124.309 459.203 124.083 458.185 123.901C456.568 123.569 455.16 123.131 453.962 122.587C452.765 122.043 451.761 121.363 450.953 120.547C450.144 119.731 449.545 118.809 449.156 117.781C448.767 116.724 448.572 115.545 448.572 114.245C448.572 112.976 448.842 111.797 449.381 110.709C449.95 109.621 450.713 108.684 451.672 107.899C452.66 107.083 453.813 106.463 455.13 106.04C456.448 105.587 457.87 105.36 459.398 105.36C461.015 105.36 462.497 105.572 463.845 105.995C465.192 106.418 466.39 107.052 467.438 107.899C468.486 108.715 469.355 109.727 470.044 110.936L466.63 114.336C466.031 113.339 465.357 112.508 464.608 111.843C463.86 111.148 463.036 110.634 462.138 110.301C461.239 109.939 460.281 109.757 459.263 109.757C458.095 109.757 457.077 109.939 456.208 110.301C455.34 110.664 454.651 111.178 454.142 111.843C453.663 112.477 453.423 113.233 453.423 114.109C453.423 114.744 453.543 115.333 453.783 115.877C454.022 116.391 454.382 116.86 454.861 117.283C455.37 117.676 456.044 118.038 456.882 118.371C457.721 118.673 458.724 118.945 459.892 119.187C461.539 119.549 463.006 120.018 464.294 120.592C465.582 121.136 466.675 121.786 467.573 122.541C468.471 123.297 469.145 124.143 469.594 125.08C470.074 126.017 470.313 127.029 470.313 128.117C470.313 130.142 469.849 131.895 468.921 133.376C467.992 134.857 466.675 136.005 464.968 136.821C463.261 137.607 461.224 138 458.859 138Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M475.929 137.547V113.747H480.601V118.824L479.747 119.368C479.987 118.582 480.361 117.827 480.87 117.101C481.409 116.376 482.053 115.741 482.802 115.197C483.58 114.623 484.404 114.17 485.272 113.837C486.171 113.505 487.084 113.339 488.012 113.339C489.36 113.339 490.543 113.565 491.561 114.019C492.579 114.472 493.418 115.152 494.076 116.059C494.735 116.965 495.214 118.099 495.514 119.459L494.795 119.277L495.11 118.507C495.439 117.812 495.888 117.162 496.457 116.557C497.056 115.923 497.73 115.364 498.479 114.88C499.227 114.396 500.021 114.019 500.859 113.747C501.698 113.475 502.521 113.339 503.33 113.339C505.097 113.339 506.549 113.701 507.687 114.427C508.855 115.152 509.723 116.255 510.292 117.736C510.891 119.217 511.191 121.06 511.191 123.267V137.547H506.519V123.539C506.519 122.179 506.339 121.076 505.98 120.229C505.651 119.353 505.142 118.703 504.453 118.28C503.764 117.857 502.881 117.645 501.803 117.645C500.964 117.645 500.17 117.796 499.422 118.099C498.703 118.371 498.074 118.764 497.535 119.277C496.996 119.791 496.577 120.396 496.277 121.091C495.978 121.756 495.828 122.496 495.828 123.312V137.547H491.157V123.448C491.157 122.209 490.977 121.166 490.618 120.32C490.258 119.444 489.734 118.779 489.045 118.325C488.357 117.872 487.518 117.645 486.53 117.645C485.692 117.645 484.913 117.796 484.194 118.099C483.475 118.371 482.847 118.764 482.308 119.277C481.769 119.761 481.349 120.35 481.05 121.045C480.75 121.71 480.601 122.436 480.601 123.221V137.547H475.929Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M526.459 138C524.512 138 522.73 137.456 521.113 136.368C519.526 135.28 518.253 133.799 517.295 131.925C516.337 130.052 515.858 127.936 515.858 125.579C515.858 123.191 516.337 121.076 517.295 119.232C518.283 117.358 519.601 115.892 521.248 114.835C522.925 113.777 524.797 113.248 526.863 113.248C528.091 113.248 529.214 113.429 530.232 113.792C531.25 114.155 532.133 114.668 532.882 115.333C533.661 115.968 534.29 116.708 534.769 117.555C535.278 118.401 535.592 119.308 535.712 120.275L534.679 119.912V113.747H539.351V137.547H534.679V131.88L535.757 131.563C535.577 132.379 535.203 133.18 534.634 133.965C534.095 134.721 533.391 135.401 532.523 136.005C531.684 136.61 530.741 137.093 529.693 137.456C528.675 137.819 527.597 138 526.459 138ZM527.671 133.693C529.079 133.693 530.322 133.346 531.4 132.651C532.478 131.956 533.316 131.004 533.915 129.795C534.544 128.556 534.859 127.15 534.859 125.579C534.859 124.037 534.544 122.662 533.915 121.453C533.316 120.244 532.478 119.292 531.4 118.597C530.322 117.902 529.079 117.555 527.671 117.555C526.294 117.555 525.066 117.902 523.988 118.597C522.94 119.292 522.102 120.244 521.473 121.453C520.874 122.662 520.574 124.037 520.574 125.579C520.574 127.15 520.874 128.556 521.473 129.795C522.102 131.004 522.94 131.956 523.988 132.651C525.066 133.346 526.294 133.693 527.671 133.693Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M545.81 137.547V113.747H550.482V121.227L550.033 119.413C550.362 118.265 550.916 117.222 551.695 116.285C552.503 115.348 553.417 114.608 554.435 114.064C555.483 113.52 556.561 113.248 557.669 113.248C558.178 113.248 558.657 113.293 559.107 113.384C559.586 113.475 559.96 113.58 560.23 113.701L559.017 118.779C558.657 118.628 558.253 118.507 557.804 118.416C557.385 118.295 556.965 118.235 556.546 118.235C555.738 118.235 554.959 118.401 554.21 118.733C553.492 119.036 552.848 119.474 552.279 120.048C551.74 120.592 551.306 121.242 550.976 121.997C550.647 122.723 550.482 123.524 550.482 124.4V137.547H545.81Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M567.724 137.547V107.672H572.351V137.547H567.724ZM562.828 118.28V113.747H577.831V118.28H562.828Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M592.523 137.547L604.696 105.813H609.637L621.72 137.547H616.554L609.367 118.416C609.278 118.204 609.098 117.691 608.828 116.875C608.589 116.059 608.304 115.152 607.975 114.155C607.645 113.157 607.346 112.251 607.076 111.435C606.807 110.588 606.627 110.044 606.537 109.803L607.571 109.757C607.391 110.271 607.166 110.921 606.897 111.707C606.657 112.492 606.388 113.324 606.088 114.2C605.819 115.076 605.549 115.908 605.28 116.693C605.01 117.449 604.786 118.084 604.606 118.597L597.464 137.547H592.523ZM597.913 129.659L599.71 124.944H613.994L616.06 129.659H597.913Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M628.787 137.547V107.672H633.413V137.547H628.787ZM623.89 118.28V113.747H638.893V118.28H623.89Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M646.026 137.547V107.672H650.653V137.547H646.026ZM641.13 118.28V113.747H656.133V118.28H641.13Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M671.132 138C668.706 138 666.55 137.486 664.663 136.459C662.807 135.401 661.339 133.965 660.261 132.152C659.213 130.339 658.689 128.253 658.689 125.896C658.689 124.022 658.989 122.315 659.587 120.773C660.186 119.232 661.01 117.902 662.058 116.784C663.136 115.636 664.409 114.759 665.876 114.155C667.373 113.52 668.991 113.203 670.727 113.203C672.255 113.203 673.677 113.505 674.995 114.109C676.312 114.684 677.45 115.484 678.409 116.512C679.397 117.54 680.146 118.764 680.655 120.184C681.194 121.574 681.448 123.1 681.418 124.763L681.373 126.757H662.103L661.07 123.04H677.375L676.702 123.811V122.723C676.612 121.725 676.282 120.834 675.713 120.048C675.144 119.262 674.426 118.643 673.557 118.189C672.689 117.736 671.746 117.509 670.727 117.509C669.11 117.509 667.748 117.827 666.64 118.461C665.532 119.066 664.693 119.972 664.124 121.181C663.555 122.36 663.271 123.826 663.271 125.579C663.271 127.241 663.615 128.692 664.304 129.931C664.993 131.14 665.966 132.076 667.224 132.741C668.481 133.406 669.934 133.739 671.581 133.739C672.749 133.739 673.827 133.542 674.815 133.149C675.833 132.756 676.926 132.046 678.094 131.019L680.43 134.328C679.711 135.053 678.828 135.688 677.78 136.232C676.762 136.776 675.669 137.214 674.501 137.547C673.363 137.849 672.24 138 671.132 138Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M686.446 137.547V113.747H691.073V118.643L690.264 119.187C690.564 118.129 691.148 117.162 692.016 116.285C692.885 115.379 693.918 114.653 695.116 114.109C696.314 113.535 697.541 113.248 698.799 113.248C700.596 113.248 702.093 113.611 703.291 114.336C704.489 115.031 705.387 116.104 705.986 117.555C706.585 119.005 706.885 120.834 706.885 123.04V137.547H702.258V123.357C702.258 121.997 702.078 120.879 701.719 120.003C701.36 119.096 700.806 118.446 700.057 118.053C699.308 117.63 698.38 117.449 697.272 117.509C696.374 117.509 695.55 117.66 694.801 117.963C694.053 118.235 693.394 118.628 692.825 119.141C692.286 119.655 691.852 120.26 691.522 120.955C691.223 121.62 691.073 122.345 691.073 123.131V137.547H688.782C688.393 137.547 688.004 137.547 687.614 137.547C687.225 137.547 686.836 137.547 686.446 137.547Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M722.998 138C720.842 138 718.911 137.471 717.204 136.413C715.527 135.325 714.194 133.86 713.206 132.016C712.218 130.142 711.724 128.012 711.724 125.624C711.724 123.236 712.203 121.121 713.161 119.277C714.149 117.404 715.482 115.938 717.159 114.88C718.836 113.792 720.737 113.248 722.864 113.248C724.032 113.248 725.154 113.444 726.233 113.837C727.341 114.2 728.329 114.714 729.197 115.379C730.066 116.013 730.739 116.724 731.219 117.509C731.728 118.265 731.982 119.036 731.982 119.821L730.635 119.912V104H735.261V137.547H730.635V131.88H731.533C731.533 132.605 731.293 133.331 730.814 134.056C730.335 134.751 729.691 135.401 728.883 136.005C728.104 136.61 727.191 137.093 726.143 137.456C725.125 137.819 724.076 138 722.998 138ZM723.627 133.965C725.035 133.965 726.277 133.603 727.356 132.877C728.434 132.152 729.272 131.17 729.871 129.931C730.5 128.661 730.814 127.226 730.814 125.624C730.814 124.022 730.5 122.602 729.871 121.363C729.272 120.093 728.434 119.096 727.356 118.371C726.277 117.645 725.035 117.283 723.627 117.283C722.22 117.283 720.977 117.645 719.899 118.371C718.821 119.096 717.967 120.093 717.339 121.363C716.74 122.602 716.44 124.022 716.44 125.624C716.44 127.226 716.74 128.661 717.339 129.931C717.967 131.17 718.821 132.152 719.899 132.877C720.977 133.603 722.22 133.965 723.627 133.965Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M750.704 138C748.758 138 746.976 137.456 745.359 136.368C743.772 135.28 742.499 133.799 741.541 131.925C740.582 130.052 740.103 127.936 740.103 125.579C740.103 123.191 740.582 121.076 741.541 119.232C742.529 117.358 743.846 115.892 745.493 114.835C747.17 113.777 749.042 113.248 751.108 113.248C752.336 113.248 753.459 113.429 754.477 113.792C755.495 114.155 756.379 114.668 757.128 115.333C757.906 115.968 758.535 116.708 759.014 117.555C759.523 118.401 759.838 119.308 759.957 120.275L758.924 119.912V113.747H763.596V137.547H758.924V131.88L760.002 131.563C759.823 132.379 759.448 133.18 758.879 133.965C758.34 134.721 757.637 135.401 756.768 136.005C755.93 136.61 754.986 137.093 753.938 137.456C752.92 137.819 751.842 138 750.704 138ZM751.917 133.693C753.324 133.693 754.567 133.346 755.645 132.651C756.723 131.956 757.562 131.004 758.161 129.795C758.79 128.556 759.104 127.15 759.104 125.579C759.104 124.037 758.79 122.662 758.161 121.453C757.562 120.244 756.723 119.292 755.645 118.597C754.567 117.902 753.324 117.555 751.917 117.555C750.539 117.555 749.312 117.902 748.234 118.597C747.185 119.292 746.347 120.244 745.718 121.453C745.119 122.662 744.82 124.037 744.82 125.579C744.82 127.15 745.119 128.556 745.718 129.795C746.347 131.004 747.185 131.956 748.234 132.651C749.312 133.346 750.539 133.693 751.917 133.693Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M770.056 137.547V113.747H774.683V118.643L773.874 119.187C774.173 118.129 774.757 117.162 775.626 116.285C776.494 115.379 777.527 114.653 778.725 114.109C779.923 113.535 781.151 113.248 782.409 113.248C784.205 113.248 785.703 113.611 786.901 114.336C788.098 115.031 788.997 116.104 789.596 117.555C790.195 119.005 790.494 120.834 790.494 123.04V137.547H785.867V123.357C785.867 121.997 785.688 120.879 785.328 120.003C784.969 119.096 784.415 118.446 783.666 118.053C782.918 117.63 781.989 117.449 780.881 117.509C779.983 117.509 779.16 117.66 778.411 117.963C777.662 118.235 777.003 118.628 776.434 119.141C775.895 119.655 775.461 120.26 775.132 120.955C774.832 121.62 774.683 122.345 774.683 123.131V137.547H772.392C772.002 137.547 771.613 137.547 771.224 137.547C770.834 137.547 770.445 137.547 770.056 137.547Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M806.788 138C804.601 138 802.64 137.456 800.903 136.368C799.196 135.28 797.834 133.799 796.815 131.925C795.827 130.052 795.333 127.951 795.333 125.624C795.333 123.297 795.827 121.196 796.815 119.323C797.834 117.449 799.196 115.968 800.903 114.88C802.64 113.792 804.601 113.248 806.788 113.248C808.884 113.248 810.785 113.686 812.492 114.563C814.229 115.409 815.547 116.572 816.445 118.053L813.885 121.227C813.406 120.532 812.792 119.897 812.043 119.323C811.294 118.748 810.501 118.295 809.662 117.963C808.824 117.63 808.015 117.464 807.237 117.464C805.799 117.464 804.512 117.827 803.374 118.552C802.266 119.247 801.382 120.214 800.723 121.453C800.065 122.692 799.735 124.083 799.735 125.624C799.735 127.165 800.08 128.556 800.768 129.795C801.457 131.004 802.37 131.971 803.508 132.696C804.646 133.421 805.904 133.784 807.282 133.784C808.09 133.784 808.869 133.648 809.617 133.376C810.396 133.104 811.145 132.696 811.863 132.152C812.582 131.608 813.256 130.943 813.885 130.157L816.445 133.331C815.487 134.691 814.109 135.809 812.313 136.685C810.546 137.562 808.704 138 806.788 138Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M831.815 138C829.389 138 827.233 137.486 825.346 136.459C823.49 135.401 822.022 133.965 820.944 132.152C819.896 130.339 819.372 128.253 819.372 125.896C819.372 124.022 819.671 122.315 820.27 120.773C820.869 119.232 821.693 117.902 822.741 116.784C823.819 115.636 825.092 114.759 826.559 114.155C828.056 113.52 829.673 113.203 831.41 113.203C832.938 113.203 834.36 113.505 835.678 114.109C836.995 114.684 838.133 115.484 839.092 116.512C840.08 117.54 840.828 118.764 841.337 120.184C841.877 121.574 842.131 123.1 842.101 124.763L842.056 126.757H822.786L821.753 123.04H838.058L837.385 123.811V122.723C837.295 121.725 836.965 120.834 836.396 120.048C835.827 119.262 835.109 118.643 834.24 118.189C833.372 117.736 832.428 117.509 831.41 117.509C829.793 117.509 828.431 117.827 827.323 118.461C826.215 119.066 825.376 119.972 824.807 121.181C824.238 122.36 823.954 123.826 823.954 125.579C823.954 127.241 824.298 128.692 824.987 129.931C825.676 131.14 826.649 132.076 827.907 132.741C829.164 133.406 830.617 133.739 832.264 133.739C833.432 133.739 834.51 133.542 835.498 133.149C836.516 132.756 837.609 132.046 838.777 131.019L841.113 134.328C840.394 135.053 839.511 135.688 838.463 136.232C837.444 136.776 836.351 137.214 835.184 137.547C834.046 137.849 832.923 138 831.815 138Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M447 184.591V159H450.875L460.198 174.172L458.333 174.135L467.766 159H471.422V184.591H467.473V174.099C467.473 171.905 467.522 169.931 467.619 168.176C467.741 166.422 467.936 164.679 468.204 162.948L468.716 164.301L460.344 177.243H457.931L449.815 164.447L450.217 162.948C450.485 164.581 450.668 166.263 450.766 167.994C450.887 169.7 450.948 171.735 450.948 174.099V184.591H447Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M485.031 184.957C483.447 184.957 481.997 184.518 480.68 183.641C479.389 182.763 478.353 181.569 477.573 180.058C476.793 178.547 476.403 176.841 476.403 174.94C476.403 173.014 476.793 171.308 477.573 169.822C478.377 168.31 479.45 167.128 480.79 166.275C482.155 165.422 483.678 164.996 485.36 164.996C486.359 164.996 487.273 165.142 488.102 165.434C488.931 165.727 489.65 166.141 490.259 166.677C490.893 167.189 491.404 167.786 491.794 168.469C492.209 169.151 492.465 169.882 492.562 170.662L491.721 170.37V165.398H495.523V184.591H491.721V180.022L492.599 179.766C492.452 180.424 492.148 181.07 491.685 181.703C491.246 182.313 490.673 182.861 489.966 183.348C489.284 183.836 488.516 184.226 487.663 184.518C486.835 184.811 485.957 184.957 485.031 184.957ZM486.018 181.484C487.164 181.484 488.175 181.204 489.052 180.643C489.93 180.082 490.612 179.315 491.1 178.34C491.612 177.341 491.867 176.207 491.867 174.94C491.867 173.697 491.612 172.588 491.1 171.613C490.612 170.638 489.93 169.87 489.052 169.31C488.175 168.749 487.164 168.469 486.018 168.469C484.897 168.469 483.898 168.749 483.02 169.31C482.167 169.87 481.485 170.638 480.973 171.613C480.485 172.588 480.242 173.697 480.242 174.94C480.242 176.207 480.485 177.341 480.973 178.34C481.485 179.315 482.167 180.082 483.02 180.643C483.898 181.204 484.897 181.484 486.018 181.484Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M500.781 184.591V165.398H504.547V169.346L503.889 169.785C504.132 168.932 504.608 168.152 505.314 167.445C506.021 166.714 506.862 166.129 507.837 165.69C508.812 165.227 509.811 164.996 510.835 164.996C512.297 164.996 513.516 165.288 514.491 165.873C515.466 166.434 516.197 167.299 516.684 168.469C517.172 169.639 517.415 171.113 517.415 172.892V184.591H513.65V173.148C513.65 172.052 513.504 171.15 513.211 170.443C512.919 169.712 512.468 169.188 511.858 168.871C511.249 168.53 510.494 168.384 509.592 168.432C508.861 168.432 508.19 168.554 507.581 168.798C506.972 169.017 506.436 169.334 505.972 169.748C505.534 170.163 505.18 170.65 504.912 171.211C504.669 171.747 504.547 172.332 504.547 172.966V184.591H502.682C502.365 184.591 502.048 184.591 501.732 184.591C501.415 184.591 501.098 184.591 500.781 184.591Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M529.909 184.957C528.324 184.957 526.874 184.518 525.558 183.641C524.266 182.763 523.231 181.569 522.451 180.058C521.671 178.547 521.281 176.841 521.281 174.94C521.281 173.014 521.671 171.308 522.451 169.822C523.255 168.31 524.327 167.128 525.668 166.275C527.033 165.422 528.556 164.996 530.238 164.996C531.237 164.996 532.151 165.142 532.98 165.434C533.808 165.727 534.527 166.141 535.137 166.677C535.77 167.189 536.282 167.786 536.672 168.469C537.086 169.151 537.342 169.882 537.44 170.662L536.599 170.37V165.398H540.401V184.591H536.599V180.022L537.476 179.766C537.33 180.424 537.026 181.07 536.562 181.703C536.124 182.313 535.551 182.861 534.844 183.348C534.162 183.836 533.394 184.226 532.541 184.518C531.712 184.811 530.835 184.957 529.909 184.957ZM530.896 181.484C532.041 181.484 533.053 181.204 533.93 180.643C534.808 180.082 535.49 179.315 535.978 178.34C536.489 177.341 536.745 176.207 536.745 174.94C536.745 173.697 536.489 172.588 535.978 171.613C535.49 170.638 534.808 169.87 533.93 169.31C533.053 168.749 532.041 168.469 530.896 168.469C529.775 168.469 528.775 168.749 527.898 169.31C527.045 169.87 526.362 170.638 525.851 171.613C525.363 172.588 525.119 173.697 525.119 174.94C525.119 176.207 525.363 177.341 525.851 178.34C526.362 179.315 527.045 180.082 527.898 180.643C528.775 181.204 529.775 181.484 530.896 181.484Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M554.25 193C552.885 193 551.52 192.781 550.156 192.342C548.815 191.928 547.731 191.379 546.902 190.697L548.291 187.772C548.779 188.138 549.351 188.454 550.009 188.723C550.667 189.015 551.362 189.234 552.093 189.381C552.824 189.551 553.543 189.637 554.25 189.637C555.591 189.637 556.712 189.417 557.614 188.978C558.515 188.54 559.198 187.894 559.661 187.041C560.124 186.188 560.356 185.128 560.356 183.86V180.168L560.758 180.424C560.636 181.179 560.234 181.91 559.551 182.617C558.893 183.3 558.065 183.86 557.065 184.299C556.066 184.738 555.042 184.957 553.994 184.957C552.166 184.957 550.533 184.53 549.095 183.677C547.682 182.8 546.561 181.618 545.732 180.131C544.903 178.62 544.489 176.902 544.489 174.976C544.489 173.051 544.891 171.345 545.695 169.858C546.524 168.347 547.645 167.165 549.059 166.312C550.497 165.434 552.105 164.996 553.885 164.996C554.616 164.996 555.323 165.093 556.005 165.288C556.687 165.459 557.321 165.715 557.906 166.056C558.491 166.373 559.015 166.726 559.478 167.116C559.941 167.506 560.319 167.92 560.612 168.359C560.904 168.798 561.087 169.212 561.16 169.602L560.356 169.895V165.398H564.121V183.422C564.121 184.957 563.89 186.31 563.427 187.48C562.988 188.674 562.342 189.673 561.489 190.477C560.636 191.306 559.6 191.928 558.381 192.342C557.163 192.781 555.786 193 554.25 193ZM554.396 181.484C555.591 181.484 556.651 181.204 557.577 180.643C558.503 180.082 559.222 179.315 559.734 178.34C560.246 177.365 560.502 176.244 560.502 174.976C560.502 173.709 560.234 172.588 559.698 171.613C559.186 170.614 558.467 169.834 557.541 169.273C556.639 168.713 555.591 168.432 554.396 168.432C553.227 168.432 552.179 168.725 551.252 169.31C550.351 169.87 549.632 170.65 549.095 171.649C548.584 172.624 548.328 173.733 548.328 174.976C548.328 176.219 548.584 177.341 549.095 178.34C549.632 179.315 550.351 180.082 551.252 180.643C552.179 181.204 553.227 181.484 554.396 181.484Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M578.358 184.957C576.384 184.957 574.629 184.543 573.093 183.714C571.582 182.861 570.388 181.703 569.511 180.241C568.657 178.778 568.231 177.097 568.231 175.196C568.231 173.685 568.475 172.308 568.962 171.065C569.45 169.822 570.12 168.749 570.973 167.847C571.85 166.921 572.886 166.214 574.08 165.727C575.299 165.215 576.615 164.959 578.029 164.959C579.272 164.959 580.43 165.203 581.502 165.69C582.574 166.153 583.5 166.799 584.28 167.628C585.085 168.457 585.694 169.444 586.108 170.589C586.547 171.71 586.754 172.941 586.73 174.282L586.693 175.89H571.009L570.169 172.892H583.44L582.891 173.514V172.637C582.818 171.832 582.55 171.113 582.087 170.48C581.624 169.846 581.039 169.346 580.332 168.981C579.625 168.615 578.857 168.432 578.029 168.432C576.713 168.432 575.604 168.688 574.702 169.2C573.8 169.687 573.118 170.419 572.655 171.394C572.192 172.344 571.96 173.526 571.96 174.94C571.96 176.28 572.24 177.45 572.801 178.449C573.361 179.424 574.154 180.18 575.177 180.716C576.201 181.252 577.383 181.52 578.723 181.52C579.674 181.52 580.551 181.362 581.356 181.045C582.184 180.728 583.074 180.156 584.024 179.327L585.926 181.996C585.341 182.581 584.622 183.092 583.769 183.531C582.94 183.97 582.05 184.323 581.1 184.591C580.174 184.835 579.26 184.957 578.358 184.957Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M590.749 184.591V165.398H594.551V169.492L593.857 169.931C594.052 169.297 594.356 168.688 594.771 168.103C595.209 167.518 595.733 167.006 596.343 166.568C596.976 166.105 597.647 165.739 598.353 165.471C599.085 165.203 599.828 165.069 600.584 165.069C601.68 165.069 602.643 165.252 603.472 165.617C604.3 165.983 604.983 166.531 605.519 167.262C606.055 167.994 606.445 168.908 606.689 170.004L606.104 169.858L606.36 169.237C606.628 168.676 606.994 168.152 607.457 167.665C607.944 167.153 608.492 166.702 609.102 166.312C609.711 165.922 610.357 165.617 611.039 165.398C611.722 165.178 612.392 165.069 613.05 165.069C614.488 165.069 615.67 165.361 616.596 165.946C617.547 166.531 618.254 167.421 618.717 168.615C619.204 169.809 619.448 171.296 619.448 173.075V184.591H615.646V173.295C615.646 172.198 615.5 171.308 615.207 170.626C614.939 169.919 614.525 169.395 613.964 169.054C613.404 168.713 612.685 168.542 611.807 168.542C611.125 168.542 610.479 168.664 609.87 168.908C609.285 169.127 608.773 169.444 608.334 169.858C607.895 170.272 607.554 170.76 607.31 171.32C607.067 171.857 606.945 172.454 606.945 173.112V184.591H603.143V173.222C603.143 172.222 602.996 171.381 602.704 170.699C602.411 169.992 601.985 169.456 601.424 169.09C600.864 168.725 600.181 168.542 599.377 168.542C598.695 168.542 598.061 168.664 597.476 168.908C596.891 169.127 596.379 169.444 595.94 169.858C595.502 170.248 595.161 170.723 594.917 171.284C594.673 171.82 594.551 172.405 594.551 173.039V184.591H590.749Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M633.446 184.957C631.472 184.957 629.717 184.543 628.182 183.714C626.671 182.861 625.477 181.703 624.599 180.241C623.746 178.778 623.32 177.097 623.32 175.196C623.32 173.685 623.563 172.308 624.051 171.065C624.538 169.822 625.208 168.749 626.061 167.847C626.939 166.921 627.975 166.214 629.169 165.727C630.388 165.215 631.704 164.959 633.117 164.959C634.36 164.959 635.518 165.203 636.591 165.69C637.663 166.153 638.589 166.799 639.369 167.628C640.173 168.457 640.783 169.444 641.197 170.589C641.636 171.71 641.843 172.941 641.819 174.282L641.782 175.89H626.098L625.257 172.892H638.528L637.98 173.514V172.637C637.907 171.832 637.639 171.113 637.175 170.48C636.712 169.846 636.127 169.346 635.421 168.981C634.714 168.615 633.946 168.432 633.117 168.432C631.801 168.432 630.692 168.688 629.791 169.2C628.889 169.687 628.206 170.419 627.743 171.394C627.28 172.344 627.049 173.526 627.049 174.94C627.049 176.28 627.329 177.45 627.889 178.449C628.45 179.424 629.242 180.18 630.266 180.716C631.289 181.252 632.472 181.52 633.812 181.52C634.763 181.52 635.64 181.362 636.444 181.045C637.273 180.728 638.163 180.156 639.113 179.327L641.014 181.996C640.429 182.581 639.71 183.092 638.857 183.531C638.029 183.97 637.139 184.323 636.188 184.591C635.262 184.835 634.348 184.957 633.446 184.957Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M645.911 184.591V165.398H649.676V169.346L649.018 169.785C649.262 168.932 649.737 168.152 650.444 167.445C651.151 166.714 651.992 166.129 652.967 165.69C653.942 165.227 654.941 164.996 655.965 164.996C657.427 164.996 658.646 165.288 659.621 165.873C660.595 166.434 661.327 167.299 661.814 168.469C662.301 169.639 662.545 171.113 662.545 172.892V184.591H658.78V173.148C658.78 172.052 658.633 171.15 658.341 170.443C658.048 169.712 657.598 169.188 656.988 168.871C656.379 168.53 655.623 168.384 654.722 168.432C653.99 168.432 653.32 168.554 652.711 168.798C652.102 169.017 651.565 169.334 651.102 169.748C650.664 170.163 650.31 170.65 650.042 171.211C649.798 171.747 649.676 172.332 649.676 172.966V184.591H647.812C647.495 184.591 647.178 184.591 646.861 184.591C646.545 184.591 646.228 184.591 645.911 184.591Z"
                    fill={PRIMARY_MAIN}
                />
                <path
                    d="M670.03 184.591V160.499H673.795V184.591H670.03ZM666.045 169.054V165.398H678.256V169.054H666.045Z"
                    fill={PRIMARY_MAIN}
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_604_14"
                        x1="-64.0755"
                        y1="229.08"
                        x2="469.114"
                        y2="173.919"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_604_14"
                        x1="384.704"
                        y1="93.5733"
                        x2="852.698"
                        y2="-68.2244"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                    <linearGradient
                        id="paint9_linear_604_14"
                        x1="390.151"
                        y1="247"
                        x2="405.698"
                        y2="246.96"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="1" stopColor="white" />
                    </linearGradient>
                </defs>
            </svg>
        </Box>
    );

    if (disabledLink) {
        return logo;
    }

    return (
        <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
            {logo}
        </Link>
    );
});

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default Logo;
