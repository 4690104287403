// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Typography, List, ListItem, ListItemIcon, ListItemText, CardContent } from '@mui/material';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import Logo from 'src/components/logo';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import AppsIcon from '@mui/icons-material/Apps';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BarChartIcon from '@mui/icons-material/BarChart';
import AlarmIcon from '@mui/icons-material/Alarm';
import PieChartIcon from '@mui/icons-material/PieChart';
import PublicIcon from '@mui/icons-material/Public';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import FilterListIcon from '@mui/icons-material/FilterList';
import PinDropIcon from '@mui/icons-material/PinDrop';
import PeopleIcon from '@mui/icons-material/People';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import CarouselAnimation from 'src/sections/_examples/extra/carousel-view/carousel-animation';

// ----------------------------------------------------------------------
const carouselContent = [
    {
        id: 1,
        coverUrl: 'https://i.ibb.co/cNS25k0/19948906-6162956.jpg',
        title: 'Welcome to TeamXcel Smart Attendance Management!',
        subtitle: (
            <>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    Thank you for choosing TeamXcel, your ultimate solution for efficient and
                    insightful attendance management. Our platform empowers you to streamline and
                    optimize employee attendance monitoring across your organization.
                </Typography>
            </>
        ),
        description: (
            <>
                <Typography variant="body3" sx={{ mb: 4 }}>
                    TeamXcel offers a comprehensive suite of features designed to enhance attendance
                    management:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <MonitorHeartIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Comprehensive Dashboards: Track employee attendance, working hours, outage hours, and daily status." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SecurityIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Time Punctuality: Analyze time punctuality with metrics for early and late check-ins and check-outs." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <AppsIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Performance Insights: Track average clock-in, clock-out times, working hours and identify trends in employee attendance." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CalendarTodayIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Leave Management: Easily track and manage employee leave requests, ensuring accurate leave balances and approvals." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <EventNoteIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Outage Tracking: Monitor and record outage hours to understand and manage disruptions in employee productivity." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PieChartIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Attendance Trends: Analyze attendance patterns over time to identify and address issues affecting employee attendance." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <AlarmIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Early/Late Check-In/Out: Set up alerts for employees who check in/out too early/late, helping to ensure punctuality." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <BarChartIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Customizable Reports: Generate reports tailored to your specific needs, including daily, weekly, and monthly summaries." />
                    </ListItem>
                </List>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Experience the power of TeamXcel&apos;s detailed attendance and time management
                    capabilities.
                </Typography>
                <br />
                <br />
                <Typography variant="body3" sx={{ mb: 4 }}>
                    Sign up now to transform how you manage and analyze employee attendance data.
                </Typography>
            </>
        ),
    },
    {
        id: 2,
        coverUrl:
            'https://i.ibb.co/1GgLdfn/geofence.jpg',
        // coverUrl:
        //     'https://i.ibb.co/DfLR6zt/DALL-E-2024-08-12-12-46-26-A-high-resolution-image-of-a-futuristic-city-with-glowing-lights-and-a-fo.webp',
        title: 'Office Hierarchy and Geo-Fencing',
        subtitle: (
            <>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    TeamXcel provides advanced functionalities for managing office hierarchies and
                    geo-fencing. Create offices for each organization, assign geo-fences, and ensure
                    accurate location-based check-ins and check-outs.
                </Typography>
            </>
        ),
        description: (
            <>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Key Features:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <BusinessIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Office Hierarchy: Create and manage offices within each organization, defining a clear organizational structure." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocationOnIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Geo-Fencing: Set geo-fences (circle, polygon, square, administrative) for precise check-ins and check-outs." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PublicIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Administrative Boundaries: Manage state boundaries and local levels for precise location-based policies." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PeopleIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Team Assignments: Allocate teams and projects to offices, ensuring check-ins/outs within designated boundaries." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <PinDropIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Multi-Boundary Support: Assign multi geo-fences/administrative boundaries for flexible, precise location management." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FilterListIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Customizable Geo-Fence: Adjust settings, alerts, and notifications to fit organizational needs." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ShowChartIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Location Analytics: Analyze check-in/check-out patterns within geo-fences to boost efficiency and compliance." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SecurityIcon fontSize="medium" />
                        </ListItemIcon>
                        <ListItemText primary="Enhanced Security: Use geo-fencing to restrict access and enforce location-based policies for better security." />
                    </ListItem>
                </List>

                <Typography variant="body4" sx={{ mb: 4 }}>
                    Leverage TeamXcel’s geo-fencing to optimize location-based operations and ensure
                    policy compliance.
                </Typography>

                <br />
                <br />
                <Typography variant="body3" sx={{ mb: 4 }}>
                    Sign up now to take advantage of advanced office hierarchy and geo-fencing
                    features for better location-based management.
                </Typography>
            </>
        ),
    },

    {
        id: 3,
        coverUrl: 'https://i.ibb.co/tHknZfc/usermanagement.jpg',
        title: 'User Management',
        subtitle: (
            <>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    TeamXcel User Management provides a robust solution for organizing and managing
                    users within your organization. The system is designed to streamline user access
                    and permissions across different enterprises.
                </Typography>
            </>
        ),
        description: (
            <>
                <Typography variant="body4" sx={{ mb: 4 }}>
                    Organisation Structure:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create and configure individual enterprises with unique settings and requirements within your organization." />
                    </ListItem>

                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create and manage users for specific enterprises." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Assign roles and permissions to users based on their responsibilities." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Granular control over user access, ensuring the right individuals have the right permissions." />
                    </ListItem>
                </List>

                <Typography variant="body4" sx={{ mb: 4 }}>
                    Role Assignment:
                </Typography>

                <List>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Create predefined roles with specific sets of permissions." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GridCheckCircleIcon />
                        </ListItemIcon>
                        <ListItemText primary="Assign roles to users within each enterprise, ensuring efficient access control." />
                    </ListItem>
                </List>

                <Typography variant="body3" sx={{ mb: 4 }}>
                    Experience the simplicity and efficiency of user management with Teamxcel.
                </Typography>
                <br />
                <br />
                <Typography variant="body3" sx={{ mb: 4 }}>
                    Sign up now to transform how you organize and secure user access within your
                    organization.
                </Typography>
            </>
        ),
    },
];

export default function AuthLayout({ children }) {
    const upMd = useResponsive('up', 'md');

    const renderContent = (
        <Stack
            sx={{
                width: 1,
                mx: 'auto',
                maxWidth: 600,
                px: { xs: 2, md: 8 },
            }}
        >
            <Logo
                sx={{
                    mt: { xs: 2, md: 8 },
                    mb: { xs: 10, md: 8 },
                }}
            />

            <Box
                sx={{
                    py: { xs: 5, md: 0 },
                    px: { xs: 3, md: 0 },
                    boxShadow: { md: 'none' },
                    overflow: { md: 'unset' },
                }}
            >
                {children}
            </Box>
        </Stack>
    );

    // const renderSection = (
    //   <Stack flexGrow={1} sx={{ position: 'relative' }}>
    //     <Box
    // component="img"
    // alt="auth"
    // src={image || '/assets/background/overlay_3.jpg'}
    // sx={{
    //   top: 16,
    //   left: 16,
    //   objectFit: 'cover',
    //   position: 'absolute',
    //   width: 'calc(100% - 32px)',
    //   height: 'calc(100% - 32px)',
    // }}
    //     />
    //   </Stack>
    // );

    const renderSection = (
        <CardContent
            sx={{
                width: 950,
            }}
        >
            <CarouselAnimation data={carouselContent} />
        </CardContent>
    );

    return (
        <Stack direction="column">
            <Stack
                component="main"
                direction="row"
                sx={{
                    minHeight: '100vh',
                    position: 'relative',
                    '&:before': {
                        width: 1,
                        height: 1,
                        zIndex: -1,
                        content: "''",
                        position: 'absolute',
                        backgroundSize: 'cover',
                        opacity: { xs: 0.24, md: 0 },
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                        backgroundImage: 'url(/assets/background/overlay_4.jpg)',
                    },
                }}
            >
                {renderContent}

                {upMd && renderSection}
            </Stack>
        </Stack>
    );
}

AuthLayout.propTypes = {
    children: PropTypes.node,
};
